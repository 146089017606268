import { Fragment, useMemo, useRef, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth, userRole } from "../../utils/AuthProvider";
import PaymentStatus from "../../components/PaymentStatus";
import { useAuthFetch, useAuthPost, useFetch } from "../../utils/hooks";
import {
  compact,
  filter,
  find,
  findIndex,
  flatten,
  includes,
  isEmpty,
  isFunction,
  keys,
  map,
  reduce,
  reverse,
  size,
  sortBy,
  toLower,
  toNumber,
  uniqBy,
} from "lodash";
import Loading from "../../components/Loading";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Input,
  Typography,
} from "@material-tailwind/react";
import NoDataFound from "../../components/NoDataFound";
import {
  ArrowDownward,
  ArrowUpward,
  FilterAltOff,
  KeyboardArrowDown,
  Minimize,
  Add,
  FilterAlt,
} from "@mui/icons-material";
import { getTime } from "date-fns/getTime";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link, useLocation } from "react-router-dom";
import DownloadXls from "../../components/DownloadXls";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

const ordering = {
  true: "asc",
  false: "desc",
};

const sortOptions = [
  {
    name: "Newest",
    keyName: "created_at",
    ordering: ordering.ascending,
    current: true,
  },
  { name: "Age", keyName: "age", ordering: ordering.ascending, current: false },
  {
    name: "Income",
    keyName: "annualIncome",
    ordering: ordering.ascending,
    current: false,
  },
];

const sortValueGenerator = {
  created_at: (val) => getTime(new Date(val)),
  age: (val) => toNumber(val),
  annualIncome: (val, defaultFilters) =>
    findIndex(
      find(defaultFilters, (x) => x.id === "annualIncome").options,
      (x) => x.value === val
    ),
};

const getSortingValue = (data, keyName, defaultFilters) => {
  return sortValueGenerator[keyName](data[keyName], defaultFilters);
};

const labelsForCandidate = {
  maritalStatus: "वैवाहिक स्थिती",
  education: "Education Qualification (शैक्षणिक पात्रता)",
  working: "Working in (व्यवसाय)",
  bloodGroup: "Blood Group (रक्त गट)",
  annualIncome: "Annual Income (वार्षिक उत्पन्न)",
  additionalFilters: "Additional Filters (अतिरिक्त फिल्टर)",
};

const labelsForAdmin = {
  gender: "Gender (लिंग)",
  paymentStatusId: "Payment Status",
  ...labelsForCandidate,
  additionalFilters: undefined,
};

const useSorting = () => {
  const [sorting, setSorting] = useState(sortOptions);

  const resetToDefaultSorting = () => {
    setSorting(sortOptions);
  };

  const updateSorting = (name, ordering) => {
    setSorting(
      map(sorting, (x) => {
        if (x.name === name) {
          return {
            ...x,
            ordering,
            current: true,
          };
        }

        return { ...x, current: false };
      })
    );
  };

  return {
    sorting,
    updateSorting,
    resetToDefaultSorting,
  };
};

const usePersistState = (name, defaultValue) => {
  const [value, setValue] = useState(
    localStorage.getItem(name)
      ? JSON.parse(localStorage.getItem(name))
      : defaultValue
  );

  const onSetValue = (val) => {
    localStorage.setItem(name, JSON.stringify(val));
    setValue(val);
  };

  console.log(value);
  return [value, onSetValue];
};

const useFilters = () => {
  const [filters, setFilters] = usePersistState("candidate_filters", []);
  const defaultFiltersRef = useRef([]);
  const { role } = useAuth();
  const resetToDefaultFiltering = (defVal) => {
    setFilters(defVal || defaultFiltersRef.current);
  };
  const labels = role === userRole.admin ? labelsForAdmin : labelsForCandidate;
  useFetch(`/data.json`, (data) => {
    const formattedData = {
      // additionalFilters: [
      //   {
      //     label: "Liked",
      //     value: (val) => val.like,
      //   },
      //   {
      //     label: "My Interest",
      //     value: (val) => val.myInterest,
      //   },
      //   {
      //     label: "Received Interest",
      //     value: (val) => val.receivedInterest,
      //   },
      //   {
      //     label: "Accepted Interest",
      //     value: (val) => val.acceptedInterest,
      //   },
      // ],
      ...data,
      paymentStatusId: [
        {
          label: "Paid",
          value: (val) => val.paymentStatusId !== null,
        },
        {
          label: "Unpaid",
          value: (val) => val.paymentStatusId === null,
        },
      ],
    };
    defaultFiltersRef.current = filter(
      map(keys(formattedData), (key) => {
        if (!labels[key]) {
          return;
        }

        return {
          id: key,
          name: labels[key],
          options: map(formattedData[key], (opt) => {
            return {
              ...opt,
              checked: false,
            };
          }),
        };
      }),
      (x) => !!x
    );

    resetToDefaultFiltering(
      map(defaultFiltersRef.current, (val) => {
        return {
          ...val,
          options: map(val.options, (opt) => {
            return {
              ...opt,
              checked:
                filters
                  .find((x) => x.id === val.id)
                  ?.options?.find((x) => x.value === opt.value)?.checked ||
                opt.checked,
            };
          }),
        };
      })
    );
  });

  const updateFilters = (section, key, checked) => {
    setFilters(
      map(filters, (x) => {
        if (x.id === section) {
          return {
            ...x,
            options: map(x.options, (opt) => {
              if (opt.label === key) {
                return {
                  ...opt,
                  checked,
                };
              }
              return opt;
            }),
          };
        }

        return x;
      })
    );
  };

  return {
    filters,
    updateFilters,
    resetToDefaultFiltering,
    defaultFilters: defaultFiltersRef.current,
  };
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SearchInput = ({ onSearch, searchText }) => {
  return (
    <div className="inline-flex flex-1">
      <Input
        size="sm"
        className="w-full"
        placeholder="Full Name"
        label="Search Candidate Names"
        value={searchText}
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
  );
};

const Sorting = ({ sorting, updateSorting }) => {
  return (
    <Menu as="div" className="relative inline-block">
      <MenuButton
        as={Button}
        variant="outlined"
        size="sm"
        className="inline-flex items-center mx-2"
      >
        Sort
        <KeyboardArrowDown
          aria-hidden="true"
          className="-mr-1 ml-1 size-5 shrink-0 text-gray-400 group-hover:text-gray-500"
        />
      </MenuButton>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {sorting.map((option) => (
            <MenuItem key={option.name}>
              <Button
                variant="text"
                className={classNames(
                  option.current
                    ? "font-medium text-gray-900"
                    : "text-gray-500",
                  "w-full text-left block px-4 py-2 text-sm data-[focus]:bg-gray-100 data-[focus]:outline-none"
                )}
                onClick={() => updateSorting(option.name, !option.ordering)}
              >
                <span className="flex justify-between">
                  {option.name}
                  <span className="ml-6 flex items-center">
                    {option.ordering ? (
                      <ArrowUpward aria-hidden="true" className="size-5" />
                    ) : (
                      <ArrowDownward aria-hidden="true" className="size-5" />
                    )}
                  </span>
                </span>
              </Button>
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  );
};

const DashboardPageHeading = ({
  sorting,
  updateSorting,
  sortedData,
  appliedFilters,
  resetToDefaultFiltering,
  resetToDefaultSorting,
  setMobileFiltersOpen,
  onSearch,
  searchText,
}) => {
  const { role } = useAuth();
  const location = useLocation();
  return (
    <>
      <div className="flex items-center justify-end md:justify-between border-b border-gray-200 pt-6">
        <Typography variant="h1" className="hidden md:block">
          Candidates
        </Typography>
        <div className="flex items-center flex-col justify-end md:flex-row w-full">
          <div className="w-full md:w-auto flex md:block justify-end items-center">
            <SearchInput onSearch={onSearch} searchText={searchText} />
            {role === userRole.admin && <DownloadXls data={sortedData} />}
            <Button
              variant="outlined"
              disabled={isEmpty(appliedFilters) && !searchText}
              onClick={() => {
                resetToDefaultFiltering();
                resetToDefaultSorting();
                onSearch("");
              }}
              size="sm"
              className="inline-flex items-center hidden mx-2 md:inline-block"
            >
              <FilterAltOff />
              <span className="hidden md:inline-block">Reset Filters</span>
            </Button>
            <Button
              variant="outlined"
              onClick={() => setMobileFiltersOpen(true)}
              size="sm"
              className="inline-flex items-center mx-2 hidden md:inline-block"
            >
              <FilterAlt />
              <span className="hidden md:inline-block">Filters</span>
            </Button>
            <div className="md:hidden">
              <Button
                variant="text"
                disabled={isEmpty(appliedFilters) && !searchText}
                onClick={() => {
                  resetToDefaultFiltering();
                  resetToDefaultSorting();
                  onSearch("");
                }}
                size="sm"
                className="z-10 print:hidden bottom-[210px] !fixed end-5 rounded-full bg-red-600 p-3 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
              >
                <FilterAltOff />
              </Button>
              <Button
                variant="text"
                onClick={() => setMobileFiltersOpen(true)}
                size="sm"
                className="z-10 print:hidden bottom-[160px] !fixed end-5 rounded-full bg-red-600 p-3 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
              >
                <FilterAlt />
              </Button>
            </div>
            <Sorting sorting={sorting} updateSorting={updateSorting} />
          </div>
        </div>
      </div>
      {role === userRole.candidate && (
        <div className="py-2 flex text-center items-center flex-col justify-center md:flex-row w-full hidden md:block border-b border-gray-200">
          <Link className="underline p-2" to="/">
            <Button
              variant={location.pathname === "/" ? "filled" : "outlined"}
              className={location.pathname === "/" ? "bg-primary" : ""}
            >
              {"All"}
            </Button>
          </Link>
          <Link className="underline p-2" to="/liked">
            <Button
              variant={location.pathname === "/liked" ? "filled" : "outlined"}
              className={location.pathname === "/liked" ? "bg-primary" : ""}
            >
              {"Liked"}
            </Button>
          </Link>
          <Link className="underline p-2" to="/my-interest">
            <Button
              variant={
                location.pathname === "/my-interest" ? "filled" : "outlined"
              }
              className={
                location.pathname === "/my-interest" ? "bg-primary" : ""
              }
            >
              {"My Interest"}
            </Button>
          </Link>
          <Link className="underline p-2" to="/received-interest">
            <Button
              variant={
                location.pathname === "/received-interest"
                  ? "filled"
                  : "outlined"
              }
              className={
                location.pathname === "/received-interest" ? "bg-primary" : ""
              }
            >
              {"Received Interest"}
            </Button>
          </Link>
          <Link className="underline p-2" to="/accepted-interest">
            <Button
              variant={
                location.pathname === "/accepted-interest"
                  ? "filled"
                  : "outlined"
              }
              className={
                location.pathname === "/accepted-interest" ? "bg-primary" : ""
              }
            >
              {"Accepted Interest"}
            </Button>
          </Link>
        </div>
      )}
    </>
  );
};

const getAd = (advertisements, index, afterCount = 4) => {
  if (index && !(index % afterCount)) {
    return advertisements?.[(index / afterCount - 1) % size(advertisements)];
  }
  return null;
};

const Filters = ({ filters, updateFilters }) => {
  const [openSection, setOpenSection] = useState(0);
  return filters.map(
    (section, index) =>
      section && (
        <Accordion
          key={section.id}
          as="div"
          className="px-4 py-6"
          open={openSection === index}
        >
          <h3 className="-mx-2 -my-3 flow-root text-xs md:text-md">
            <AccordionHeader
              onClick={() => setOpenSection(index)}
              className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500"
            >
              <span className="font-medium text-gray-900 flex-1">
                {section.name}
              </span>
              <span className="ml-6 flex items-center">
                {openSection === section.id ? (
                  <Minimize className="size-5" />
                ) : (
                  <Add className="size-5" />
                )}
              </span>
            </AccordionHeader>
          </h3>
          <AccordionBody className="pt-6">
            <div className="space-y-6">
              {section.options.map((option, optionIdx) => (
                <div key={option.value} className="flex items-center">
                  <input
                    id={`filter-mobile-${section.id}-${optionIdx}`}
                    name={`${section.id}[]`}
                    type="checkbox"
                    checked={option.checked}
                    onChange={(e) =>
                      updateFilters(section.id, option.label, e.target.checked)
                    }
                    className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                    className="ml-3 min-w-0 flex-1 text-gray-500"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </AccordionBody>
        </Accordion>
      )
  );
};

const DashboardPage = ({ data, advertisements, likedCandidatesRefetch }) => {
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { role } = useAuth();
  const { filters, defaultFilters, updateFilters, resetToDefaultFiltering } =
    useFilters();
  const { sorting, updateSorting, resetToDefaultSorting } = useSorting();

  const appliedFilters = flatten(
    compact(
      map(filters, (x) => {
        const options = compact(
          map(x.options, (opt) => {
            if (opt.checked) {
              return isFunction(opt.value) ? opt.value : { [x.id]: opt.value };
            }
            return;
          })
        );
        if (isEmpty(options)) {
          return;
        }
        return {
          ...x,
          options,
        };
      })
    )
  );

  const searchResult = useMemo(
    () =>
      searchText
        ? filter(data, (x) => includes(toLower(x.name), toLower(searchText)))
        : data,
    [data, searchText]
  );

  const filteredData = useMemo(
    () =>
      isEmpty(appliedFilters)
        ? searchResult
        : reduce(
            appliedFilters,
            (result, x) =>
              uniqBy(
                flatten(map(x.options, (opt) => filter(result, opt))),
                "id"
              ),
            searchResult
          ),
    [appliedFilters, searchResult]
  );

  const appliedSortings = find(sorting, (x) => x.current);

  const sortedData = useMemo(() => {
    const result = sortBy(filteredData, (x) =>
      getSortingValue(x, appliedSortings.keyName, defaultFilters)
    );
    return appliedSortings.ordering ? result : reverse(result);
  }, [
    appliedSortings.keyName,
    appliedSortings.ordering,
    defaultFilters,
    filteredData,
  ]);

  const { makeRequest: onLikeCandidate } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_likes/like`,
    (data) => {
      if (data.success) {
        likedCandidatesRefetch();
      }
    }
  );

  const { makeRequest: onRemoveLikeCandidate } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_likes/dislike`,
    (data) => {
      if (data.success) {
        likedCandidatesRefetch();
      }
    }
  );

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Dialog
          open={mobileFiltersOpen}
          onClose={setMobileFiltersOpen}
          className="relative z-40 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black/25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex size-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex size-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="size-6" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200">
                {filters && (
                  <Filters filters={filters} updateFilters={updateFilters} />
                )}
              </form>
            </DialogPanel>
          </div>
        </Dialog>

        <main className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
          <div className="mt-6">
            {role === userRole.candidate && <PaymentStatus />}
          </div>
          <DashboardPageHeading
            sorting={sorting}
            updateSorting={updateSorting}
            sortedData={sortedData}
            appliedFilters={appliedFilters}
            resetToDefaultFiltering={resetToDefaultFiltering}
            resetToDefaultSorting={resetToDefaultSorting}
            setMobileFiltersOpen={setMobileFiltersOpen}
            onSearch={setSearchText}
            searchText={searchText}
          />

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                <Typography variant="h3">Filters</Typography>
                {filters && (
                  <Filters filters={filters} updateFilters={updateFilters} />
                )}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="text-right py-2"
                >
                  {`Showing result, ${size(sortedData)} of ${size(data)}`}
                </Typography>
                <LazyLoadComponent>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  >
                    <Masonry gutter="1rem">
                      {map(sortedData, (candidate, index) => {
                        const ad = getAd(advertisements, index);
                        return (
                          <Fragment key={candidate.id}>
                            {ad && (
                              <Card className="border-2 shadow-xl">
                                <CardHeader floated={false} color="blue-gray">
                                  <LazyLoadImage
                                    src={ad.image}
                                    className="w-full"
                                    alt="ui/ux review check"
                                  />
                                  <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
                                </CardHeader>
                                <CardBody className="px-4 py-2">
                                  <div className="mb-3">
                                    {ad.title && (
                                      <Typography
                                        variant="h5"
                                        color="blue-gray"
                                        className="font-medium break-all	"
                                      >
                                        {ad.title}
                                      </Typography>
                                    )}
                                    {ad.sub_title && (
                                      <Typography
                                        variant="h6"
                                        color="gray"
                                        className="font-medium break-all	"
                                      >
                                        {ad.sub_title}
                                      </Typography>
                                    )}
                                  </div>
                                  {ad.description && (
                                    <Typography
                                      color="gray"
                                      className="text-xs md:text-sm font-medium break-all	"
                                    >
                                      {ad.description}
                                    </Typography>
                                  )}
                                </CardBody>
                              </Card>
                            )}
                            <Card className="border-2 shadow-xl">
                              <CardHeader floated={false} color="blue-gray">
                                <LazyLoadImage
                                  src={candidate.image}
                                  className="w-full"
                                  alt="ui/ux review check"
                                />
                                <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
                                {role === userRole.candidate && (
                                  <IconButton
                                    size="sm"
                                    color={candidate.like ? "red" : "gray"}
                                    variant="text"
                                    className="!absolute top-4 right-4 rounded-full"
                                    disabled
                                    onClick={() =>
                                      candidate.like
                                        ? onRemoveLikeCandidate({
                                            id: +candidate.id,
                                          })
                                        : onLikeCandidate({
                                            id: +candidate.id,
                                          })
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      className="h-6 w-6"
                                    >
                                      <path
                                        stroke="#fff"
                                        d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"
                                      />
                                    </svg>
                                  </IconButton>
                                )}
                              </CardHeader>
                              <CardBody className="text-center px-4 py-2">
                                <div className="mb-3 flex items-center justify-between">
                                  <Typography
                                    variant="h5"
                                    color="blue-gray"
                                    className="font-medium"
                                  >
                                    {candidate.name}
                                  </Typography>
                                </div>
                                <Typography
                                  color="gray"
                                  className="text-xs md:text-sm font-medium"
                                >
                                  {`${candidate.educationInfo}`}
                                </Typography>
                                <div className="flex flex-wrap justify-center mt-4 mx-6">
                                  <div className="text-xs mr-2 my-1 uppercase tracking-wider border px-2 text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-indigo-100 cursor-default">
                                    {`Age: ${candidate.age}`}
                                  </div>
                                  <div className="text-xs mr-2 my-1 uppercase tracking-wider border px-2 text-green-600 border-green-600 hover:bg-green-600 hover:text-green-100 cursor-default">
                                    {candidate.maritalStatus}
                                  </div>
                                </div>
                              </CardBody>
                              <CardFooter className="pt-3">
                                <Link to={`/candidate/${candidate.id}`}>
                                  <Button size="lg" variant="" fullWidth={true}>
                                    View Full Profile
                                  </Button>
                                </Link>
                              </CardFooter>
                            </Card>
                          </Fragment>
                        );
                      })}
                    </Masonry>
                  </ResponsiveMasonry>
                </LazyLoadComponent>
                {/* Your content */}
              </div>
            </div>
          </section>
        </main>
      </div>
      {role === userRole.candidate && (
        <div className="fixed md:hidden bottom-0 z-50 w-full -translate-x-1/2 bg-white border-t border-gray-200 left-1/2 dark:bg-gray-700 dark:border-gray-600">
          <div className="w-full text-center">
            <div
              className="grid max-w-xs grid-cols-2 gap-1 p-1 mx-auto my-2 bg-gray-100 rounded-lg dark:bg-gray-600"
              role="group"
            >
              <Link
                className={
                  location.pathname === "/"
                    ? "px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg"
                    : "px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg"
                }
                to="/"
              >
                {"All"}
              </Link>
              <Link
                className={
                  location.pathname === "/liked"
                    ? "px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg"
                    : "px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg"
                }
                to="/liked"
              >
                {"Liked"}
              </Link>
            </div>
            <div
              className="grid max-w-xs grid-cols-3 gap-1 p-1 mx-auto my-2 bg-gray-100 rounded-lg dark:bg-gray-600"
              role="group"
            >
              <Link
                className={
                  location.pathname === "/my-interest"
                    ? "px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg"
                    : "px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg"
                }
                to="/my-interest"
              >
                {"My Interest"}
              </Link>
              <Link
                className={
                  location.pathname === "/received-interest"
                    ? "px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg"
                    : "px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg"
                }
                to="/received-interest"
              >
                {"Received"}
              </Link>
              <Link
                className={
                  location.pathname === "/accepted-interest"
                    ? "px-5 py-1.5 text-xs font-medium text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded-lg"
                    : "px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg"
                }
                to="/accepted-interest"
              >
                {"Accepted"}
              </Link>
            </div>
          </div>

          <div className="grid h-full max-w-lg grid-cols-5 mx-auto hidden">
            <button
              data-tooltip-target="tooltip-home"
              type="button"
              className="inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              <span className="sr-only">Home</span>
            </button>
            <div
              id="tooltip-home"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              Home
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
              data-tooltip-target="tooltip-bookmark"
              type="button"
              className="inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 14 20"
              >
                <path d="M13 20a1 1 0 0 1-.64-.231L7 15.3l-5.36 4.469A1 1 0 0 1 0 19V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v17a1 1 0 0 1-1 1Z" />
              </svg>
              <span className="sr-only">Bookmark</span>
            </button>
            <div
              id="tooltip-bookmark"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              Bookmark
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
              data-tooltip-target="tooltip-post"
              type="button"
              className="inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
              <span className="sr-only">New post</span>
            </button>
            <div
              id="tooltip-post"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              New post
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
              data-tooltip-target="tooltip-search"
              type="button"
              className="inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
            <div
              id="tooltip-search"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              Search
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
              data-tooltip-target="tooltip-settings"
              type="button"
              className="inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50 dark:hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
                />
              </svg>
              <span className="sr-only">Settings</span>
            </button>
            <div
              id="tooltip-settings"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              Settings
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const filterCalculatedData = (
  data,
  { liked, myInterested, receivedInterested, acceptedInterest }
) => {
  if (liked) {
    return filter(data, (d) => d.like);
  }
  if (myInterested) {
    return filter(data, (d) => d.myInterest);
  }
  if (receivedInterested) {
    return filter(data, (d) => d.receivedInterest);
  }
  if (acceptedInterest) {
    return filter(data, (d) => d.acceptedInterest);
  }

  return data;
};

const CandidateDashboard = (props) => {
  const { data, isLoading } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidates/filtered`
  );

  const { data: advertisements } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/advertisements_active`
  );

  const { data: likedCandidates, refetch: likedCandidatesRefetch } =
    useAuthFetch(`${process.env.REACT_APP_API_BASE_URI}/api/candidate_likes`);

  const { data: candidateInterests } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_show_interests`
  );

  const { data: receivedCandidates } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_show_interests/inMe`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return <NoDataFound />;
  }

  const calculatedData = data.map((l) => {
    return {
      ...l,
      like: likedCandidates?.find((x) => x.candidate_like_id === l.id),
      acceptedInterest:
        candidateInterests?.find((x) => x.candidate_interest_id === l.id) &&
        receivedCandidates?.find((x) => x.candidate_id === l.id),
      receivedInterest:
        !candidateInterests?.find((x) => x.candidate_interest_id === l.id) &&
        receivedCandidates?.find((x) => x.candidate_id === l.id),
      myInterest: candidateInterests?.find((x) => x.candidate_interest_id === l.id),
    };
  });

  return (
    <DashboardPage
      data={filterCalculatedData(calculatedData, props)}
      advertisements={advertisements}
      likedCandidatesRefetch={likedCandidatesRefetch}
    />
  );
};

const AdminDashboard = () => {
  const { data, isLoading } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidates/filtered`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return <NoDataFound />;
  }

  return <DashboardPage data={data} />;
};

const Dashboard = (props) => {
  const { role } = useAuth();

  if (role === userRole.admin) {
    return <AdminDashboard />;
  }

  return <CandidateDashboard {...props} />;
};

export default Dashboard;
