import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import useGenerateLinks from "./useGenerateLinks";
import SubFooter from "./SubFooter";
import { Fragment } from "react";
import { ArrowUpward } from "@mui/icons-material";

const footerData = {
  applicationName: process.env.REACT_APP_WEBSITE_NAME,
  karyalay: [
    "कार्यालय:- ज्योती प्रमोद राखुंडे,",
    "बोकारे प्लॉटस,डॉ. चिदरवार हास्पीटल समोर,",
    "रामनगर, चंद्रपूर - 442401",
  ],
  contacts: [
    {
      name: "अध्यक्ष",
      phone: "9975649585",
    },
    {
      name: "कार्याध्यक्ष",
      phone: "9822729117",
    },
    {
      name: "महासचिव",
      phone: "7666088059",
    },
  ],
};

const Footer = () => {
  const links = useGenerateLinks();

  return (
    <footer
      id="footer"
      className="bg-primary py-6 relative w-full print:hidden"
    >
      <button
        type="button"
        data-twe-ripple-init
        data-twe-ripple-color="light"
        className={`print:hidden bottom-[110px] !fixed end-5 rounded-full bg-red-600 p-3 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg
          `}
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }
        id="btn-back-to-top"
      >
        <ArrowUpward className="w-2" />
      </button>
      <div className="mx-auto w-full max-w-7xl px-8">
        <div
          className={"grid grid-cols-1 justify-between gap-4 md:grid-cols-2"}
        >
          <div>
            <div className="text-secondary">
              <Typography variant="h5" className="mb-4">
                {footerData.applicationName}
              </Typography>
              {footerData.karyalay.map((karyalay, karyalayIndex) => (
                <Typography
                  key={karyalayIndex}
                  variant="small"
                  className="font-medium"
                >
                  {karyalay}
                </Typography>
              ))}

              <br />
            </div>
            <div>
              {footerData.contacts.map((contact, contactIndex) => (
                <Fragment key={contactIndex}>
                  <strong>{`${contact.name}:${contact.phone}`}</strong>
                  <br />
                </Fragment>
              ))}
            </div>
          </div>
          <div className="grid md:grid-cols-2 justify-between gap-4">
            {links.map(({ title, items }) => (
              <ul key={title}>
                <Typography
                  variant="small"
                  className="text-secondary mb-3 font-medium"
                >
                  {title}
                </Typography>
                {items.map(({ title, link }) => (
                  <li key={title}>
                    <Link
                      to={link}
                      className="py-1.5 font-normal transition-colors hover:text-blue-gray-900"
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <SubFooter applicationName={footerData.applicationName} />
      </div>
    </footer>
  );
};

export default Footer;
