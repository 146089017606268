import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import Routes from "./utils/routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import AuthProvider from "./utils/AuthProvider";
import { enGB } from "date-fns/locale/en-GB";

const newTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            zIndex: 99999,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "2.75rem",
            borderRadius: "0.375rem !important",
          },
          input: {
            padding: "0.65rem !important",
            color: "rgb(69 90 100)",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "rgb(120 144 156);",
            top: "-5px !important",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "rgb(176 190 197)",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: "100%",
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            color: "#F9E795",
            borderRadius: "0px",
            borderWidth: "0px",
            borderColor: "#e91e63",
            border: "0px solid",
            backgroundColor: "#F96167",
          },
        },
      },
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer />
    <AuthProvider>
      <ThemeProvider theme={newTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={enGB}>
          <Routes />
        </LocalizationProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);
