import { Link } from "react-router-dom";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../Loading";
import AlertBox from "./AlertBox";
import useMarkedPaymentStatus from "./useMarkedPaymentStatus";

const title = "नोंद:";
const message =
  "आपण ₹200 नोंदणी शुल्क भरलेले नाही. कृपया 10 जानेवारी 2025 पूर्वी पेमेंट पूर्ण करा. अन्यथा, आपले प्रोफाइल वार्षिक स्मरणिकेत छापले जाणार नाही आणि आपण इतर उपवर-उपवधूंच्या प्रोफाइल पाहू शकणार नाही.";

const PaymentStatus = ({ alwaysShow = false }) => {
  const { isLoading, data, refetch } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/razorpay_payment_logs`
  );

  useMarkedPaymentStatus(refetch);

  if (isLoading || !data) {
    return <Loading />;
  }

  if (!data?.[0]?.log) {
    return (
      <AlertBox
        color="red"
        title={title}
        message={
          <>
            {message}
            <br />
            <Link to="/payment" className="font-bold underline text-white">
              Re-try payment
            </Link>
          </>
        }
      />
    );
  }

  if (!JSON.parse(data?.[0]?.log)?.razorpay_payment_id) {
    return (
      <AlertBox
        color="red"
        title={title}
        message={
          <>
            {message}
            <br />
            {data?.[0]?.log}
          </>
        }
      />
    );
  }

  if (alwaysShow) {
    return (
      <AlertBox
        color="green"
        title="Success"
        message={`Payment was successful with the payment id - ${
          data?.[0]?.log && JSON.parse(data?.[0]?.log)?.razorpay_payment_id
        }.`}
      />
    );
  }

  return null;
};

export default PaymentStatus;
