import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import ScrollToTop from "../components/ScrollToTop";

const AuthenticatedMain = () => (
  <>
    <Header />
    <main id="main" className="flex-1 bg-secondary bg-opacity-20 mt-[65px] md:mt-0">
      <Outlet />
    </main>
    <Footer />
    <ScrollToTop />
  </>
);

export default AuthenticatedMain;
