import React from "react";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import Loading from "../../components/Loading";
import { useAuthFetch, useAuthPost, useFetch } from "../../utils/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import PaymentLogs from "../admin/PaymentLogs";
import { useAuth, userRole } from "../../utils/AuthProvider";
import { reduce } from "lodash";
import { getPasswordInput, getTextInput, labels } from "./Registration";
import PaymentStatus from "../../components/PaymentStatus";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import {
  Delete,
  Favorite,
  Interests,
  Password,
  Print,
} from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FormModal from "../../components/FormModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required().label(labels["email"]),
  password: yup
    .string()
    .required()
    .min(8, `${labels["password"]} is too short - should be 8 chars minimum.`)
    .matches(
      /[a-zA-Z]/,
      `${labels["password"]} can only contain Latin letters.`
    )
    .label(labels["password"]),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      `${labels["confirm_password"]} must match`
    )
    .required()
    .label(labels["confirm_password"]),
});

const CandidateProfileBody = ({ user, staticData }) => {
  const { role } = useAuth();
  return (
    <div className="px-4 sm:px-6 lg:px-8 print:p-3 print:h-full print:flex">
      {role === userRole.candidate && (
        <div className="pt-6 print:hidden">
          <PaymentStatus />
        </div>
      )}
      <div className="mx-auto max-w-screen-lg py-12 print:py-0">
        <Card className="p-0 border border-gray-300 print:h-full">
          <CardBody className="p-0">
            <div className="flex flex-col">
              <div>
                <div className="flex flex-col md:flex-row items-stretch">
                  <div
                    className="w-full md:w-72 flex items-center rounded-t-xl md:rounded-r-none"
                    style={{
                      background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${user.image})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <LazyLoadImage
                      className="w-full max-h-[500px] rounded-t-xl md:rounded-r-none"
                      src={user.image}
                      alt="profile"
                    />
                  </div>
                  <div className="flex-1 p-6">
                    <Typography variant="h2" className="text-center mb-2">
                      {user.name}
                    </Typography>
                    <div className="md:px-6 pt-2 border-t-2">
                      <table className="table-fixed w-full">
                        <tbody>
                          {user.gender && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["gender"]}:
                              </td>
                              <td className="p-1 break-words">
                                {staticData[user.gender] || user.gender}
                              </td>
                            </tr>
                          )}
                          {user.maritalStatus && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["maritalStatus"]}:
                              </td>
                              <td className="p-1 break-words">
                                {staticData[user.maritalStatus] ||
                                  user.maritalStatus}
                              </td>
                            </tr>
                          )}
                          {user.dob && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["dob"]}:
                              </td>
                              <td className="p-1 break-words">{user.dob}</td>
                            </tr>
                          )}
                          {user.age && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["age"]}:
                              </td>
                              <td className="p-1 break-words">{user.age}</td>
                            </tr>
                          )}
                          {user.height && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["height"]}:
                              </td>
                              <td className="p-1 break-words">{user.height}</td>
                            </tr>
                          )}
                          {user.weight && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["weight"]}:
                              </td>
                              <td className="p-1 break-words">{user.weight}</td>
                            </tr>
                          )}
                          {user.bloodGroup && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["bloodGroup"]}:
                              </td>
                              <td className="p-1 break-words">
                                {staticData[user.bloodGroup] || user.bloodGroup}
                              </td>
                            </tr>
                          )}
                          {user.educationInfo && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["educationInfo"]}:
                              </td>
                              <td className="p-1 break-words">
                                {user.educationInfo}
                              </td>
                            </tr>
                          )}
                          {user.working && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["working"]}:
                              </td>
                              <td className="p-1 break-words">
                                {staticData[user.working] || user.working}
                              </td>
                            </tr>
                          )}
                          {user.placeOfWork && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["placeOfWork"]}:
                              </td>
                              <td className="p-1 break-words">
                                {user.placeOfWork}
                              </td>
                            </tr>
                          )}
                          {user.annualIncome && (
                            <tr>
                              <td className="p-1 font-bold">
                                {labels["annualIncome"]}:
                              </td>
                              <td className="p-1 break-words">
                                {user.annualIncome}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-6 border-t-2 print:p-2">
                <table className="table-fixed w-full">
                  <tbody>
                    {user.fatherName && (
                      <tr>
                        <td className="p-1 font-bold">
                          {labels["fatherName"]}:
                        </td>
                        <td className="p-1 break-words">{user.fatherName}</td>
                      </tr>
                    )}
                    {user.motherName && (
                      <tr>
                        <td className="p-1 font-bold">
                          {labels["motherName"]}:
                        </td>
                        <td className="p-1 break-words">{user.motherName}</td>
                      </tr>
                    )}
                    {user.phone && (
                      <tr>
                        <td className="p-1 font-bold">{labels["phone"]}:</td>
                        <td className="p-1 break-words">{user.phone}</td>
                      </tr>
                    )}
                    {user.email && (
                      <tr>
                        <td className="p-1 font-bold">{labels["email"]}:</td>
                        <td className="p-1 break-words">{user.email}</td>
                      </tr>
                    )}
                    {user.address && (
                      <tr>
                        <td className="p-1 font-bold">{labels["address"]}:</td>
                        <td className="p-1 break-words">{user.address}</td>
                      </tr>
                    )}
                    {user.state && (
                      <tr>
                        <td className="p-1 font-bold">{labels["state"]}:</td>
                        <td className="p-1 break-words">{user.state}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="p-6 border-t-2 print:p-2">
                <table className="table-fixed w-full">
                  <tbody>
                    <tr>
                      <td className="p-1 font-bold">{labels["birthTime"]}:</td>
                      <td className="p-1 break-words">{user.birthTime}</td>
                      <td className="p-1 hidden md:table-cell">
                        {labels["birthPlace"]}:
                      </td>
                      <td className="p-1 hidden md:table-cell break-words">
                        {user.birthPlace}
                      </td>
                    </tr>
                    <tr className="md:hidden">
                      <td className="p-1 font-bold">{labels["birthPlace"]}:</td>
                      <td className="p-1 break-words">{user.birthPlace}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="p-6 border-t-2 print:p-2">
                <table className="table-fixed w-full">
                  <tbody>
                    <tr>
                      <td className="p-1 font-bold">{labels["mamKul"]}:</td>
                      <td className="p-1 break-words">{user.mamKul}</td>
                      <td className="p-1 hidden md:table-cell">
                        {labels["mamKulAddress"]}:
                      </td>
                      <td className="p-1 hidden md:table-cell break-words">
                        {user.mamKulAddress}
                      </td>
                    </tr>
                    <tr className="md:hidden">
                      <td className="p-1 font-bold">
                        {labels["mamKulAddress"]}:
                      </td>
                      <td className="p-1 break-words">{user.mamKulAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const CandidateProfile = ({ hideCandidateFeatures }) => {
  const { role } = useAuth();
  let { candidateId } = useParams();
  const navigate = useNavigate();
  const { isLoading, data } = useAuthFetch(
    candidateId
      ? `${process.env.REACT_APP_API_BASE_URI}/api/candidate/${candidateId}`
      : `${process.env.REACT_APP_API_BASE_URI}/api/candidate`
  );

  const { isLoading: staticDataIsLoading, data: staticData } =
    useFetch(`/data.json`);

  const { data: likedCandidates, refetch: likedCandidatesRefetch } =
    useAuthFetch(`${process.env.REACT_APP_API_BASE_URI}/api/candidate_likes`);

  const { data: candidateInterests, refetch: candidateInterestRefetch } =
    useAuthFetch(
      `${process.env.REACT_APP_API_BASE_URI}/api/candidate_show_interests`
    );

  const { data: receivedCandidates } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_show_interests/inMe`
  );

  const { makeRequest: onLikeCandidate } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_likes/like`,
    (data) => {
      if (data.success) {
        likedCandidatesRefetch();
      }
    }
  );

  const { makeRequest: onRemoveLikeCandidate } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_likes/dislike`,
    (data) => {
      if (data.success) {
        likedCandidatesRefetch();
      }
    }
  );

  const { makeRequest: onShowInterest } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate_show_interests/send`,
    (data) => {
      if (data.success) {
        likedCandidatesRefetch();
        candidateInterestRefetch();
      }
    }
  );

  if (isLoading || !data || staticDataIsLoading) {
    return <Loading />;
  }

  const { id, password, updated_at, created_at, ...user } = data;
  const like = likedCandidates?.find((x) => x.candidate_like_id === id);
  const interested = candidateInterests?.find(
    (x) => x.candidate_interest_id === id
  );

  const receivedInterest = receivedCandidates?.find(
    (x) => x.candidate_id === id
  );

  const fieldConfigurations = {
    sections: [
      {
        title: "",
        inputGroups: [
          [getTextInput("email", { readOnly: true })],
          [getPasswordInput("password")],
          [getPasswordInput("confirm_password")],
        ],
      },
    ],
  };

  return (
    <div className="mx-auto flex-1 max-w-screen-xl print:py-0 print:h-full">
      <CandidateProfileBody
        id={id}
        user={user}
        staticData={reduce(
          staticData,
          (acc, val) => {
            return {
              ...acc,
              ...reduce(val, (a, v) => ({ ...a, [v.value]: v.label }), {}),
            };
          },
          {}
        )}
      />
      <div className="hidden md:flex mx-auto max-w-screen-lg print:hidden flex-col md:flex-row justify-center items-center pb-4">
        {role === userRole.admin && (
          <>
            <DeleteConfirmationModal
              title="Delete Candidate Profile"
              form={{
                deleteUrl: `candidate/${candidateId}`,
                onSubmit: () => navigate("/"),
              }}
              renderButton={({ handleOpen }) => (
                <div className="flex justify-center">
                  <Button
                    className="flex items-center gap-3 m-2"
                    onClick={handleOpen}
                    size="sm"
                  >
                    <Delete strokeWidth={2} className="h-4 w-4" />
                    {"Delete candidate profile"}
                  </Button>
                </div>
              )}
            />
            <FormModal
              title="Update Login Password"
              submitButtonTitle="Update"
              form={{
                defaultValues: {
                  email: user.email,
                  password: "",
                },
                resolver: yupResolver(schema),
                fieldConfigurations,
                postUrl: "candidate/update_password",
                labels,
                onSubmit: () => null,
              }}
              renderButton={({ handleOpen }) => (
                <Button
                  className="flex items-center gap-3 m-2"
                  onClick={handleOpen}
                  size="sm"
                >
                  <Password strokeWidth={2} className="h-4 w-4" />
                  {"Update Password"}
                </Button>
              )}
            />
            <Link to={`/admin/candidate-edit/${candidateId}`}>
              <Button className="flex items-center gap-3 m-2" size="sm">
                <Print strokeWidth={2} className="h-4 w-4" />
                {"Edit"}
              </Button>
            </Link>
          </>
        )}
        <div className="flex justify-center">
          <Button
            className="flex items-center gap-3 m-2"
            onClick={() => window.print()}
            size="sm"
          >
            <Print strokeWidth={2} className="h-4 w-4" />
            {"Print"}
          </Button>
        </div>
        {role === userRole.candidate && (
          <>
            {hideCandidateFeatures && (
              <div className="flex justify-center">
                <Link to="/edit_profile">
                  <Button className="flex items-center gap-3 m-2" size="sm">
                    <Print strokeWidth={2} className="h-4 w-4" />
                    {"Edit"}
                  </Button>
                </Link>
              </div>
            )}
            {!hideCandidateFeatures && (
              <>
                <div className="flex justify-center">
                  <Button
                    className="flex items-center gap-3 m-2"
                    size="sm"
                    disabled={interested}
                    onClick={() =>
                      like
                        ? onRemoveLikeCandidate({
                            id: +id,
                          })
                        : onLikeCandidate({
                            id: +id,
                          })
                    }
                  >
                    <span style={{ color: like ? "white" : "red" }}>
                      <Favorite className="h-4 w-4" />
                    </span>
                    {like ? "Remove Like" : "Like"}
                  </Button>
                </div>
                <div className="flex justify-center">
                  <Button
                    className="flex items-center gap-3 m-2"
                    size="sm"
                    disabled={interested}
                    onClick={() =>
                      onShowInterest({
                        id: +id,
                      })
                    }
                  >
                    <Interests strokeWidth={2} className="h-4 w-4" />
                    {receivedInterest
                      ? "Accept Interest"
                      : interested
                      ? "Interest Sent"
                      : "Show Interest"}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {role === userRole.admin && (
        <div className="mx-auto max-w-screen-lg print:hidden">
          <div className="px-4 sm:px-6 lg:px-0">
            <PaymentLogs />
          </div>
        </div>
      )}
      <div className="fixed md:hidden bottom-0 z-50 w-full -translate-x-1/2 bg-white border-t border-gray-200 left-1/2 dark:bg-gray-700 dark:border-gray-600">
        <div className="w-full text-center">
          {role === userRole.admin && (
            <div
              className="flex justify-center items-center flex-wrap w-full max-w-xs p-1 mx-auto my-2 bg-gray-100 rounded-lg dark:bg-gray-600"
              role="group"
            >
              {role === userRole.admin && (
                <>
                  <DeleteConfirmationModal
                    title="Delete Candidate Profile"
                    form={{
                      deleteUrl: `candidate/${candidateId}`,
                      onSubmit: () => navigate("/"),
                    }}
                    renderButton={({ handleOpen }) => (
                      <div className="flex justify-center">
                        <button
                          className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap	"
                          onClick={handleOpen}
                          size="sm"
                        >
                          <Delete strokeWidth={2} className="h-4 w-4 mr-2" />
                          {"Delete"}
                        </button>
                      </div>
                    )}
                  />
                  <FormModal
                    title="Update Login Password"
                    submitButtonTitle="Update"
                    form={{
                      defaultValues: {
                        email: user.email,
                        password: "",
                      },
                      resolver: yupResolver(schema),
                      fieldConfigurations,
                      postUrl: "candidate/update_password",
                      labels,
                      onSubmit: () => null,
                    }}
                    renderButton={({ handleOpen }) => (
                      <button
                        className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap	"
                        onClick={handleOpen}
                        size="sm"
                      >
                        <Password strokeWidth={2} className="h-4 w-4 mr-2" />
                        {"Update Password"}
                      </button>
                    )}
                  />
                  <Link
                    to={`/admin/candidate-edit/${candidateId}`}
                    className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap	"
                  >
                    <Print strokeWidth={2} className="h-4 w-4 mr-2" />
                    {"Edit"}
                  </Link>
                </>
              )}
              <button
                className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap	"
                onClick={() => window.print()}
                size="sm"
              >
                <Print strokeWidth={2} className="h-4 w-4 mr-2" />
                {"Print"}
              </button>
            </div>
          )}
          {role === userRole.candidate && (
            <div
              className="flex justify-center items-center w-full max-w-xs p-1 mx-auto my-2 bg-gray-100 rounded-lg dark:bg-gray-600"
              role="group"
            >
              <button
                className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap	"
                onClick={() => window.print()}
                size="sm"
              >
                <Print strokeWidth={2} className="h-4 w-4 mr-2" />
                {"Print"}
              </button>
              {hideCandidateFeatures && (
                <Link
                  to="/edit_profile"
                  className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap	"
                >
                  <Print strokeWidth={2} className="h-4 w-4 mr-2" />
                  {"Edit"}
                </Link>
              )}
              {!hideCandidateFeatures && (
                <>
                  <button
                    className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap disabled:bg-red-100"
                    size="sm"
                    disabled={interested}
                    onClick={() =>
                      like
                        ? onRemoveLikeCandidate({
                            id: +id,
                          })
                        : onLikeCandidate({
                            id: +id,
                          })
                    }
                  >
                    <span style={{ color: like ? "black" : "red" }}>
                      <Favorite className="h-4 w-4 mr-2" />
                    </span>
                    {like ? "Remove Like" : "Like"}
                  </button>
                  <button
                    className="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 rounded-lg whitespace-nowrap disabled:bg-red-100"
                    size="sm"
                    disabled={interested}
                    onClick={() =>
                      onShowInterest({
                        id: +id,
                      })
                    }
                  >
                    <Interests strokeWidth={2} className="h-4 w-4 mr-2" />
                    {receivedInterest
                      ? "Accept Interest"
                      : interested
                      ? "Interest Sent"
                      : "Show Interest"}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateProfile;
