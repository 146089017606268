import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import {
  Avatar,
  Button,
  Checkbox,
  Input,
  Option,
  Select,
  Textarea,
} from "@material-tailwind/react";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactImageUploading from "react-images-uploading";
import avatarImg from "../assets/avatar.jpg";
import uploadImg from "../assets/upload.jpg";

export const calculateAge = (date) => {
  var dob = new Date(date);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);

  //extract year from date
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  return Math.abs(year - 1970);
};

export const toTitleCase = (str) => {
  if (!str?.replace) {
    return str;
  }

  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
};

export const PasswordInput = (props) => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative flex w-full">
      <Input
        {...props}
        type={show ? "text" : "password"}
        className={`${props.className} pr-20`}
        containerProps={{
          className: "min-w-0",
        }}
      />
      <Button
        size="sm"
        onClick={() => setShow(!show)}
        color="blue-gray"
        className="!absolute right-1 top-1"
      >
        {show ? <EyeIcon className="w-5" /> : <EyeSlashIcon className="w-5" />}
      </Button>
    </div>
  );
};

export const CustomDatePicker = ({ value, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const refDate = useRef();

  const valueProps = value
    ? {
        value: parse(value, "dd-MM-yyyy", new Date()),
      }
    : {};
  return (
    <MobileDatePicker
      {...props}
      {...valueProps}
      isOpen={isOpen}
      showTitle={false}
      clearText=""
      closeText="Set"
      onClose={() => {
        if (refDate.current) {
          props.setValue(props.name, format(refDate.current, "dd-MM-yyyy"), {
            shouldValidate: true,
            shouldDirty: true,
          });
          props.setValue(
            "age",
            calculateAge(format(refDate.current, "MM-dd-yyyy")),
            {
              shouldValidate: true,
              shouldDirty: true,
            }
          );
        }
        setIsOpen(false);
      }}
      onChange={(e) => {
        refDate.current = e;
      }}
      minDate={new Date(1970, 1, 1)}
      maxDate={new Date()}
      headerFormat="DD, MM dd"
    />
  );
};

export const CustomTimePicker = ({ value, ...props }) => {
  const refTime = useRef();

  const valueProps = value
    ? {
        value: parse(value, "hh:mm aa", new Date()),
      }
    : {};
  return (
    <MobileTimePicker
      {...props}
      {...valueProps}
      onClose={() => {
        if (refTime.current) {
          props.setValue(props.name, format(refTime.current, "hh:mm aa"), {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }}
      onChange={(e) => {
        refTime.current = e;
      }}
    />
  );
};

const useRenderInputs = (labels, formData) => {
  return (inputConfig) => {
    const {
      control,
      setValue,
      formState: { errors },
    } = formData;

    if (inputConfig.type === "avatar") {
      return (
        <div className="my-2">
          <Controller
            control={control}
            name={inputConfig.id}
            render={({ field }) => {
              return (
                <div className="flex items-center justify-center">
                  <ReactImageUploading
                    value={[field.value || avatarImg]}
                    onChange={(e) => field.onChange(e[0].dataURL, true)}
                    acceptType={["jpg", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      isDragging,
                      dragProps,
                      errors,
                    }) => {
                      return (
                        <div className="flex flex-col justify-center items-center">
                          <Avatar
                            src={imageList[0]}
                            alt="avatar"
                            className="w-32 h-32"
                          />
                          <Button
                            onClick={onImageUpload}
                            className="mt-2 bg-primary"
                            {...dragProps}
                          >
                            {labels[field.name]}
                          </Button>
                        </div>
                      );
                    }}
                  </ReactImageUploading>
                </div>
              );
            }}
          />
          {errors[inputConfig.id]?.message && (
            <p className="mt-2 flex items-center justify-center gap-1 font-normal text-xs text-red-500">
              {errors[inputConfig.id]?.message}
            </p>
          )}
        </div>
      );
    }

    if (inputConfig.type === "image") {
      return (
        <div className="my-2">
          <Controller
            control={control}
            name={inputConfig.id}
            render={({ field }) => {
              return (
                <div className="flex items-center justify-center">
                  <ReactImageUploading
                    value={[field.value || uploadImg]}
                    onChange={(e) => field.onChange(e[0].dataURL, true)}
                    acceptType={["jpg", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      isDragging,
                      dragProps,
                      errors,
                    }) => {
                      return (
                        <div className="flex flex-col justify-center items-center">
                          <Button
                            variant="text"
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <img
                              src={imageList[0]}
                              alt="avatar"
                              className="h-32 w-full rounded-lg object-cover object-center"
                            />
                            <p className="pt-4">{labels[field.name]}</p>
                          </Button>
                        </div>
                      );
                    }}
                  </ReactImageUploading>
                </div>
              );
            }}
          />
          {errors[inputConfig.id]?.message && (
            <p className="mt-2 flex items-center justify-center gap-1 font-normal text-xs text-red-500">
              {errors[inputConfig.id]?.message}
            </p>
          )}
        </div>
      );
    }

    if (inputConfig.type === "select") {
      const options = [
        {
          label: "Select (निवडा)",
          value: "",
        },
        ...inputConfig.options,
      ];

      return (
        <div className="my-2">
          <Controller
            control={control}
            name={inputConfig.id}
            render={({ field, fieldState }) => (
              <Select
                size="lg"
                label={labels[inputConfig.id]}
                error={fieldState.error ? true : false}
                selected={() =>
                  options.find((x) => x.value === field.value)?.label
                }
                {...field}
              >
                {options.map((opt) => (
                  <Option value={opt.value} key={opt.value}>
                    {opt.label}
                  </Option>
                ))}
              </Select>
            )}
          />
          {errors[inputConfig.id]?.message && (
            <p className="mt-2 flex items-center gap-1 font-normal text-xs text-red-500">
              {errors[inputConfig.id]?.message}
            </p>
          )}
        </div>
      );
    }

    if (inputConfig.type === "textarea") {
      return (
        <>
          <div className="my-2">
            <Controller
              control={control}
              name={inputConfig.id}
              render={({ field, fieldState }) => (
                <Textarea
                  size="lg"
                  {...inputConfig}
                  error={fieldState.error ? true : false}
                  label={labels[inputConfig.id]}
                  {...field}
                  onChange={(e) => {
                    if (
                      field.name === "log" ||
                      field.name === "educationInfo" ||
                      field.name === "email"
                    ) {
                      field.onChange(e.target.value);
                    } else {
                      field.onChange(toTitleCase(e.target.value));
                    }
                  }}
                />
              )}
            />
            {errors[inputConfig.id]?.message && (
              <p className="mt-2 flex items-center gap-1 font-normal text-xs text-red-500">
                {errors[inputConfig.id]?.message}
              </p>
            )}
          </div>
        </>
      );
    }

    if (inputConfig.type === "datepicker") {
      return (
        <>
          <div className="my-2">
            <Controller
              control={control}
              name={inputConfig.id}
              render={({ fieldState, field }) => (
                <CustomDatePicker
                  size="lg"
                  className="h-11"
                  {...inputConfig}
                  {...field}
                  error={fieldState.error ? true : false}
                  label={labels[inputConfig.id]}
                  setValue={setValue}
                />
              )}
            />
            {errors[inputConfig.id]?.message && (
              <p className="mt-2 flex items-center gap-1 font-normal text-xs text-red-500">
                {errors[inputConfig.id]?.message}
              </p>
            )}
          </div>
        </>
      );
    }

    if (inputConfig.type === "timepicker") {
      return (
        <>
          <div className="my-2">
            <Controller
              control={control}
              name={inputConfig.id}
              render={({ fieldState, field }) => (
                <CustomTimePicker
                  size="lg"
                  className="h-11"
                  {...inputConfig}
                  {...field}
                  error={fieldState.error ? true : false}
                  label={labels[inputConfig.id]}
                  setValue={setValue}
                />
              )}
            />
            {errors[inputConfig.id]?.message && (
              <p className="mt-2 flex items-center gap-1 font-normal text-xs text-red-500">
                {errors[inputConfig.id]?.message}
              </p>
            )}
          </div>
        </>
      );
    }

    if (inputConfig.type === "password") {
      return (
        <>
          <div className="my-2">
            <Controller
              control={control}
              name={inputConfig.id}
              render={({ field, fieldState }) => (
                <PasswordInput
                  size="lg"
                  {...inputConfig}
                  error={fieldState.error ? true : false}
                  label={labels[inputConfig.id]}
                  {...field}
                  onChange={
                    inputConfig.type === "text"
                      ? (e) => {
                          if (
                            field.name === "log" ||
                            field.name === "educationInfo" ||
                            field.name === "email"
                          ) {
                            field.onChange(e.target.value);
                          } else {
                            field.onChange(toTitleCase(e.target.value));
                          }
                        }
                      : field.onChange
                  }
                />
              )}
            />
            {errors[inputConfig.id]?.message && (
              <p className="mt-2 flex items-center gap-1 font-normal text-xs text-red-500">
                {errors[inputConfig.id]?.message}
              </p>
            )}
          </div>
        </>
      );
    }

    if (inputConfig.type === "checkbox") {
      return (
        <>
          <div className="my-2">
            <Controller
              control={control}
              name={inputConfig.id}
              render={({ field, fieldState }) => (
                <Checkbox
                  size="lg"
                  {...inputConfig}
                  error={fieldState.error ? true : false}
                  label={labels[inputConfig.id]}
                  {...field}
                  defaultChecked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {inputConfig.note && (
              <p className="mt-2 flex items-center gap-1 font-normal text-xs">
                {inputConfig.note}
              </p>
            )}
            {errors[inputConfig.id]?.message && (
              <p className="mt-2 flex items-center gap-1 font-normal text-xs text-red-500">
                {errors[inputConfig.id]?.message}
              </p>
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="my-2">
          <Controller
            control={control}
            name={inputConfig.id}
            render={({ field, fieldState }) => (
              <Input
                size="lg"
                {...inputConfig}
                error={fieldState.error ? true : false}
                label={labels[inputConfig.id]}
                {...field}
                onChange={
                  inputConfig.type === "text"
                    ? (e) => {
                        if (
                          field.name === "log" ||
                          field.name === "educationInfo" ||
                          field.name === "email"
                        ) {
                          field.onChange(e.target.value);
                        } else {
                          field.onChange(toTitleCase(e.target.value));
                        }
                      }
                    : field.onChange
                }
              />
            )}
          />
          {inputConfig.note && (
            <p className="mt-2 flex items-center gap-1 font-normal text-xs">
              {inputConfig.note}
            </p>
          )}
          {errors[inputConfig.id]?.message && (
            <p className="mt-2 flex items-center gap-1 font-normal text-xs text-red-500">
              {errors[inputConfig.id]?.message}
            </p>
          )}
        </div>
      </>
    );
  };
};

export default useRenderInputs;
